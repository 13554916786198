@media only screen and (max-width: 600px) {
  .DayTracker {
    padding: 20px;
  }
}

.days-since {
  min-height: 50vh;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.milestones-header {
  min-height: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.milestones-body {
  min-height: 48vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

p {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}